import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://ritualgym-api-uk-development.azurewebsites.net/api/v1/',
  headers: {
    Authorization: 'Token deduRcRBNuOZ2ACfxl5',
  },
});

export default instance;
